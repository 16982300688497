@media screen and (min-width: 750px) {
  .PlaceInfo {
    padding: 5vw !important;
  }

  .PlaceInfoButtonContainer {
    margin-bottom: -18%;
    width: 100vw;
  }

  .VideoInfo {
    margin-left: 37%;
  }
}

.App {
  position: absolute;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: white;
  object-fit: fill;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.App video {
  /* Make video always 100% view width and height */
  min-height: 100vh;
  object-fit: fill;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* Lots of styling to make the link modal work */
.AppLink {
  min-width: 100vw;
  min-height: 100vh;
  object-fit: fill;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #000000;
  overflow: hidden;
}

.AppLink .modal {
  margin-left: 30%;
  width: 40%;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.AppLink a {
  line-height: 16px;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
}

.AppLink button {
  padding: 15px 20px;
  background-color: white;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  border-radius: 5px;
}

.PlaceName {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 5px;
}


/* Bottom of placeinfo buttons */
.ReservationButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ReservationButtons button {
  width: 45vw;
}

.DirectionButton {
  background-color: black !important;
  color: white;
}

.MakeReservationButton {
  background-color: white;
  color: black;
  border: 2px solid grey !important;
  box-sizing: border-box;
  border-radius: 16px;
}

/* Styling for detail text overlay  */
.VideoInfo {
  position: absolute;
  z-index: 1;
  top: 60%;
  padding: 10px;
}

.ImageWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
}

.VideoInfo a {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-align: left;
}

.VideoInfo img {
  margin-top: -7px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}

.CategoryButtons {
  position: absolute;
  z-index: 1;
  top: 3%;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 35px;
}

.CategoryButtons button {
  background-color: transparent;
  color: white;
  width: 40vw;
  padding: 10px 25px 10px 25px;
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 16px;
}

.PlaceInfoButtonContainer {
  justify-content: center;
  display: flex;
}

.PlaceInfoButton {
  background-color: transparent;
  color: white;
  width: 90vw;
  padding: 10px 25px 10px 25px;
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 20%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.PlaceInfoContainer {
  letter-spacing: 0.05em;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: flex-end;
  width: 100%;
}

.PlaceInfo {
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  text-transform: uppercase;
  padding: 20px;
  font-style: bold;
  letter-spacing: 0.05em;
}

.PlaceInfo a {
  font-size: 12px;
  padding-left: 10px;
  padding-top: 1px;
}

.PlaceInfo h1 {
  padding-left: 10px;
  text-shadow: 1px 0 #000000;
  font-size: 36px;
}

.PlaceInfo h4 {
  text-shadow: .5px 0 #000000;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  padding-left: 10px;
  margin-block-start: 1.5%;
  font-weight: bold;
  line-height: 8px;
}

.PlaceInfo button {
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin: 5px;
  background: #f6f6f6;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
}

.PlaceInfo .closeButton {
  font-size: 20px;
  position: absolute;
  height: 48px;
  width: 48px;
  right: 4%;
  border-radius: 50%;
}

.StarRating {
  display: flex;
  flex-direction: row;
  width: 120px;
  padding: 10px;
}

.DayHours {
  display: flex;
  flex-direction: row;
  color: black;
  vertical-align:top;
}

svg {
  width: 100%;
  height: 100%;
}

#map {
  z-index: 10;
  height: 60vh;
  width: 100vw;
}